
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


/* Add this CSS to your index.css or app.css */

@keyframes longPressAnimation {
  from {
    width: 0%;
    background-color: lightgreen;
  }
  to {
    width: 100%;
    background-color: lightgreen;
  }
}

.long-press-active::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  background-color: lightgreen;
  animation: longPressAnimation 2s linear forwards;
}

.task-item {
  position: relative;
}



.task-icon {
  font-size: 1.5rem;
  margin-left: 0.5rem;
}


.bg-orange-400 {
  background-color: #f6ad55;
}

.task-item {
  position: relative;
  user-select: none; /* Prevent text selection */
}

.checkmark {
  display: inline-block;
  width: 16px;
  height: 16px;
  border: 1px solid #ccc;
  border-radius: 3px;
  position: relative;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

input[type="checkbox"]:checked + .checkmark:after {
  display: block;
  background-color: #3490dc;
  width: 12px;
  height: 12px;
  border: solid #fff;
  border-width: 0 0 0 0;
  transform: rotate(45deg);
  border-radius: 2px;
  content: "";
}


.connection-warning {
  background-color: #f8d7da;
  color: #721c24;
  padding: 10px;
  border: 1px solid #f5c6cb;
  margin: 20px 0;
  text-align: center;
}

.refresh-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 4px;
  margin-top: 10px;
}

.refresh-button:hover {
  background-color: #0056b3;
}